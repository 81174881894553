import { Component, Vue } from 'vue-property-decorator'
import { UserService } from '@/App/Services/User.service'

@Component
export default class ConfirmUserEmailPage extends Vue {
  public isActivated = false
  public text = 'Please wait, we activated your account'
  public logo = require('../../../assets/logo-login-screen.png')

  private async created() {
    if (!this.$route.query.code) {
      this.$router.push('/login')
    } else {
      try {
        await UserService.activate(this.$route.query.code.toString())

        this.text = 'Activated success, redirect...'
        this.isActivated = true
      } catch {} finally {
        setTimeout(() => this.$router.push('/login'), 3000)
      }
    }
  }
}
